import { gsap } from 'gsap'

export default {
  name: 'page',
  mode: 'out-in',
  css: false,
  beforeEnter(el) {
    el.style.opacity = '0'
    el.style.visibility = 'hidden'
  },
  enter(el, done) {
    const vm = this
    const header = el.querySelector('#header')
    const content = el.querySelector('#content')
    const footer = document.querySelector('#site-footer')
    const tl = gsap.timeline({
      paused: true,
      onComplete: done,
      callbackScope: vm
    })
    this.$store.commit('screen/flushSectionColors')
    if (content) {
      tl.set(content, { autoAlpha: 0 })
      tl.set(el, { autoAlpha: 1 })
    } else {
      tl.set(el, { autoAlpha: 0 })
    }
    if (header && content) {
      tl.set(header, { autoAlpha: 0 }).to(header, {
        autoAlpha: 1,
        delay: 0.25,
        duration: 1
      })
    }
    if (content) {
      tl.to(content, { autoAlpha: 1 })
    } else {
      tl.to(el, { autoAlpha: 1 })
    }
    if (footer) {
      tl.set(footer, { autoAlpha: 1, y: 250 }).to(
        footer,
        { y: 0, duration: 0.75 },
        '-=0.25'
      )
    }
    tl.play()
  },
  enterCancelled() {
    this.$store.commit('screen/setTransitioning', false)
    this.$store.commit('screen/flushSectionColors')
  },
  afterEnter() {
    this.$store.commit('screen/setTransitioning', false)
    this.$store.commit('screen/flushSectionColors')
  },
  leave(el, done) {
    const vm = this
    gsap
      .timeline({ onComplete: done, callbackScope: vm })
      .to('#site-footer', { autoAlpha: 0, y: 200 }, 0)
      .to(el, { autoAlpha: 0, y: 100 }, 0)
  }
}
