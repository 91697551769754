<template>
  <div class="product-row">
    <div class="container relative z-base">
      <div
        :is="tag"
        v-if="products.length"
        :class="classes"
        :target="0.9"
        smooth
        @visible="visibilityChange"
        @update="floatProgress = $event"
      >
        <div class="product-row__thumbs-wrapper">
          <header v-if="title || subtitle" class="section__header scheme-light">
            <h2 v-if="title" class="section__title heading">
              {{ title }}
            </h2>
            <div v-if="subtitle" class="section__subtitle heading--sm">
              <h3>{{ subtitle }}</h3>
            </div>
          </header>

          <div class="product-row__thumbs text-scheme">
            <div
              v-for="i in dummyThumbs"
              :key="`dummy-pre-${i}`"
              class="product-row__thumb"
            />
            <product-thumb
              v-for="(product, i) in products"
              :key="`product-thumb-${i}`"
              ref="thumbs"
              contain
              :product="product"
              :float="floatProgress === 0.9"
              :title="product.title"
              :square="square"
              :no-ratio="noRatio"
              :ratio="ratio"
              :hover-title="hoverTitle"
              :hover-images="hoverImages"
              :set-colors-manually="setColorsManually"
              class="product-row__thumb"
              @over="$emit('over', { colors: $event.colors })"
              @out="$emit('out')"
            />
            <div
              v-for="i in dummyThumbs"
              :key="`dummy-post-${i}`"
              class="product-row__thumb"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import random from 'lodash/random'
import { mapState, mapGetters } from 'vuex'
import { gsap } from 'gsap'
import ProductThumb from './product-thumb.vue'

export default {
  name: 'ProductRow',

  components: {
    ProductThumb
  },

  props: {
    products: {
      default: () => [],
      type: Array
    },
    floatDown: {
      default: false,
      type: Boolean
    },
    square: {
      default: false,
      type: Boolean
    },
    noRatio: {
      default: false,
      type: Boolean
    },
    hoverTitle: {
      default: undefined,
      type: Boolean
    },
    hoverImages: {
      default: undefined,
      type: Boolean
    },
    setColorsManually: {
      default: undefined,
      type: Boolean
    },
    useCommonRatio: {
      type: Boolean,
      default: false,
      required: false
    },
    minSlots: {
      type: Number,
      required: false,
      default: undefined
    },
    title: {
      default: null,
      type: String
    },
    subtitle: {
      default: null,
      type: String
    }
  },

  data: () => ({
    floatTimeline: null,
    floatProgress: 0
  }),

  computed: {
    ...mapState('screen', {
      height: 'height'
    }),
    ...mapGetters('screen', {
      bp: 'breakpoint'
    }),
    tag() {
      return this.floatDown ? 'scroller' : 'div'
    },
    classes() {
      return {
        'product-row--float-down': this.floatDown
      }
    },
    ratio() {
      if (!this.useCommonRatio) {
        return undefined
      }

      return Math.min(
        ...this.products.map(
          ({ thumb }) => (1 / thumb.image.metadata.aspectRatio) * 100
        )
      )
    },
    dummyThumbs() {
      return this.minSlots !== undefined
        ? Math.max(0, Math.floor((this.minSlots - this.products.length) / 2))
        : 0
    }
  },

  watch: {
    floatProgress(position) {
      const tl = this.floatTimeline
      if (tl) {
        tl.progress(position)
      }
    }
  },

  beforeDestroy() {
    this.killFloatTimeline()
  },

  methods: {
    visibilityChange(visible) {
      const floats = this.floatDown
      if (floats && visible) {
        this.setupFloatTimeline()
      } else if (floats && !visible) {
        this.killFloatTimeline()
      }
    },
    setupFloatTimeline() {
      let thumbs = this.$refs.thumbs
      if (!thumbs || thumbs.length <= 0) {
        return
      }
      thumbs = thumbs.map(t => t.$el)
      const offset = this.height / 2
      const offsetRandom = offset / 6
      this.floatTimeline = gsap
        .timeline({
          paused: true,
          smoothChildTiming: true,
          repeat: -1
        })
        .set(thumbs, {
          autoAlpha: 0,
          scale: 0.25,
          rotate: i => {
            return i % 2 ? random(-20, -10) : random(10, 20)
          },
          translateY: i => -1 * (offset + random(0, offsetRandom))
        })
        .add('start')
        .to(thumbs, {
          autoAlpha: 1,
          scale: 1,
          ease: 'elastic.out(1, 0.75)',
          delay: 0.25,
          duration: 1,
          stagger: {
            each: 0.1,
            from: 'random'
          }
        })
        .to(
          thumbs,
          {
            rotate: 0,
            translateY: 0,
            duration: 1.5,
            ease: 'none'
          },
          'start'
        )
    },
    killFloatTimeline() {
      if (this.floatTimeline) {
        this.floatTimeline.kill()
      }
    }
  }
}
</script>

<style>
.product-row {
  --thumb-height: theme('height.win-h-1/3');
  left: calc(-1 * var(--thumb-gutter));
  position: relative;
  width: calc(100% + (var(--thumb-gutter) * 2));
}

.product-row--float-down {
  min-height: calc(var(--win-height) * 2);
}

.product-row--float-down .product-row__thumbs-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(var(--win-height) - var(--header-height));
  padding: theme('spacing.y-gutter-sm') 0;
  position: sticky;
  top: var(--header-height);
}

.product-row__thumbs {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 100%;
}

.product-row--float-down .product-row__thumbs {
  height: 100%;
}

.product-row__thumb {
  flex: 1 1 25%;
  max-width: 25%;
}

.product-row--float-down .product-row__thumb {
  --thumb-top-left-end: translate(-75%, -50%) scale(1) rotate(0deg);
  --thumb-bottom-right-end: translate(75%, 50%) scale(1) rotate(0deg);
  --thumb-bottom-left-end: translate(-75%, 50%) scale(0.75) rotate(10deg);
  --thumb-top-right-end: translate(40%, -50%) scale(0.75) rotate(-10deg);
  --thumb-top-center-end: translate(-50%, -50%) scale(0.66) rotate(0deg);
  --thumb-bottom-center-end: translate(-50%, 50%) scale(0.66) rotate(0deg);
  opacity: 0;
}

.product-row--float-down .thumb__bottom {
  position: relative;
  width: 100%;
}

.product-row--float-down .thumb__title {
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}

.product-row--float-down.is-scrolled .thumb__title {
  opacity: 1;
  visibility: visible;
}

.section__header {
  flex: 0 1 auto;
  margin-bottom: theme('spacing.y-gutter-sm');
  position: relative;
  text-align: center;
  width: 100%;
}

.section__title {
  display: inline-block;
  font-weight: theme('fontWeight.medium');
  max-width: 100%;
  position: relative;
}

@screen md {
  .section__header {
    margin-left: auto;
    margin-right: auto;
  }

  .section__subtitle {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
}

@media (min-height: 768px) and (min-width: theme('screens.lg')) {
  .product-row__thumbs {
    justify-content: center;
  }
}

@screen lg {
  .product-row--float-down .product-row__thumb {
    --thumb-block-hover-size: 50%;
  }
}

@screen xl {
  .product-row--float-down .product-row__thumb {
    --thumb-block-hover-size: 75%;
  }
}
</style>
