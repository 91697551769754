<template>
  <div v-if="bp && products.length > 0">
    <product-row
      v-if="bp.lg"
      :products="products"
      :use-common-ratio="useCommonRatio"
      :float-down="floatDown"
      :square="square"
      :hover-title="hoverTitle"
      :hover-images="hoverImages"
      :set-colors-manually="setColorsManually"
      :min-slots="minProductSlots"
      :title="title"
      :subtitle="subtitle"
      @over="$emit('over', { colors: $event.colors })"
      @out="$emit('out')"
    />
    <product-gallery
      v-else
      :products="products"
      :float-down="floatDown"
      :square="square"
      :title="title"
      :subtitle="subtitle"
      :hover-title="hoverTitle"
      :hover-images="hoverImages"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductRow from './product-row.vue'
import ProductGallery from './product-gallery.vue'

export default {
  name: 'Products',

  components: {
    ProductRow,
    ProductGallery
  },

  props: {
    products: {
      default: () => [],
      type: Array
    },
    floatDown: {
      default: false,
      type: Boolean
    },
    square: {
      default: false,
      type: Boolean
    },
    hoverTitle: {
      default: undefined,
      type: Boolean
    },
    hoverImages: {
      default: undefined,
      type: Boolean
    },
    setColorsManually: {
      default: undefined,
      type: Boolean
    },
    useCommonRatio: {
      type: Boolean,
      required: false,
      default: false
    },
    minProductSlots: {
      type: Number,
      required: false,
      default: undefined
    },
    title: {
      default: null,
      type: String
    },
    subtitle: {
      default: null,
      type: String
    }
  },

  computed: {
    ...mapGetters('screen', {
      bp: 'breakpoint'
    })
  }
}
</script>
