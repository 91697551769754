<template>
  <div
    v-observe-visibility="visibilityChange"
    :class="classes"
    class="product-gallery"
  >
    <div v-if="title || subtitle" class="container">
      <header class="section__header">
        <h2 v-if="title" class="section__title heading">
          {{ title }}
        </h2>
        <div v-if="subtitle" class="section__subtitle heading--sm">
          <h3>{{ subtitle }}</h3>
        </div>
      </header>
    </div>
    <sky v-if="bp && bp.xl && floatDown" class="h-win-h-1/4 md:h-win-h" />
    <gallery
      v-if="slides.length > 0"
      ref="gallery"
      :slides="slides"
      :options="galleryOptions"
      class="product-gallery__gallery"
      @slide-change="slideChange"
    >
      <div
        v-for="(product, i) in slides"
        :key="`product-slide-${i}`"
        :class="slideClass(product)"
        class="product-gallery__slide swiper-slide"
      >
        <product-thumb
          ref="thumbs"
          :product="product"
          :title="product.title"
          :prevent-route="activeIndex !== i"
          swiper
          :hover-title="hoverTitle"
          :hover-images="hoverImages"
          :not-lazy="loop"
          set-colors-manually
          :image-class="loop ? '' : 'swiper-lazy'"
          :style="{
            '--size-slide-thumb-aspect-ratio': product.thumb.aspectRatio
          }"
          class="product-gallery__thumb"
        />
      </div>
    </gallery>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProductThumb from './product-thumb.vue'

export default {
  name: 'ProductGallery',

  components: {
    ProductThumb
  },

  props: {
    products: {
      default: () => [],
      type: Array
    },
    floatDown: {
      default: false,
      type: Boolean
    },
    hoverTitle: {
      default: undefined,
      type: Boolean
    },
    hoverImages: {
      default: undefined,
      type: Boolean
    },
    setsActiveColor: {
      default: true,
      type: Boolean
    },
    loop: {
      default: false,
      type: Boolean
    },
    slidesPerView: {
      default: 'auto',
      type: [String, Number]
    },
    title: {
      default: null,
      type: String
    },
    subtitle: {
      default: null,
      type: String
    }
  },

  async fetch() {
    const products = this.products.filter(p => !!p.thumb && !!p.thumb.image)
    const imageUrls = []
    const getImageUrls = async products => {
      for (const {
        thumb: { image }
      } of products) {
        const url = await this.getImageUrl({
          image,
          width: 640,
          height: 640
        })
        imageUrls.push(url)
      }
    }
    if (!products || !products.length) {
      return
    }
    await getImageUrls(products)
    const slides = products.map((s, i) => ({
      ...s,
      thumb: {
        ...s.thumb,
        imageSrc: imageUrls[i],
        aspectRatio: s.thumb.image.metadata.aspectRatio || 1
      }
    }))
    this.slides = slides
  },

  data: props => ({
    activeIndex: null,
    slides: []
  }),

  computed: {
    ...mapGetters('screen', {
      bp: 'breakpoint'
    }),
    galleryOptions() {
      const { slidesPerView, loop } = this
      return {
        slidesPerView,
        loop,
        lazy: !loop
      }
    },
    classes() {
      return {
        'product-gallery--water-only': this.waterOnly
      }
    },
    waterOnly() {
      const products = this.products
      let only = false
      if (!products || products.length === 0) {
        return only
      }
      only =
        products.filter(p =>
          [
            'sparkling-water',
            'recess-mood',
            'gift-cards',
            'zero-proof'
          ].includes(p.category)
        ).length === products.length
      return only
    }
  },

  beforeDestroy() {
    this.flushColors()
  },

  methods: {
    ...mapActions({
      getImageUrl: 'getImageUrl'
    }),
    slideClass(product) {
      return {
        [`product-gallery__slide--${product.category}`]: product.category
      }
    },
    slideChange(index) {
      if (this.loop) {
        // this behavior is incompatible with loop mode
        return
      }
      const active = this.activeIndex
      const thumbs = this.$refs.thumbs
      if (!thumbs || thumbs.length <= 0) {
        return
      }
      const newThumb = thumbs[index]
      const oldThumb = active !== null && thumbs[active]
      if (newThumb) {
        this.setsActiveColors && newThumb.setColors()
        this.activeIndex = index
      }
      if (oldThumb) {
        this.setsActiveColors && oldThumb.unsetColors()
      }
    },
    visibilityChange(visible) {
      if (visible && this.setsActiveColors) {
        this.setActiveColors()
      }
    },
    flushColors() {
      const thumbs = this.$refs.thumbs || []
      thumbs.forEach(thumb => thumb.unsetColors())
    },
    setActiveColors() {
      const gallery = this.$refs.gallery
      const thumbs = this.$refs.thumbs
      if (!gallery || !thumbs || !thumbs.length) {
        return
      }
      const index = gallery.getActiveIndex()
      const active = thumbs[index]
      if (active) {
        active.setColors()
      }
    }
  }
}
</script>

<style>
.product-gallery__gallery {
  --size-slide-width: min(70%, 16rem);
  --size-slide-thumb-max-height-base: min(12rem, calc(8rem + 10vw));
  z-index: theme('zIndex.base');
}

.product-gallery__slide {
  display: flex;
  height: auto;
  justify-content: center;
  width: var(--size-slide-width);
}

.product-gallery__slide--realitywear {
  --size-slide-width: 60%;
}

.product-gallery--water-only .product-gallery__slide .thumb {
  --thumb-hover-size: 45%;
  --thumb-top-left-end: translate(-75%, 0) scale(1) rotate(0deg);
  --thumb-bottom-right-end: translate(75%, 0) scale(1) rotate(0deg);
  --thumb-bottom-left-end: translate(-75%, 0) scale(1) rotate(0deg);
  --thumb-top-right-end: translate(75%, 0) scale(1) rotate(0deg);
}

.product-gallery__thumb {
  align-items: center;
  margin-bottom: var(--y-gutter-sm);
  margin-top: var(--y-gutter-sm);
}

.product-gallery__slide .thumb__content {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
}

.product-gallery__slide .thumb__content > *,
.product-gallery__slide .product-thumb__image .image__img {
  /* Adjust max-height based on aspect-ratio */
  /* Soften the effect by normalizing the ratio (get the average of [ar, ar, 1]) */
  --height-fac: calc((var(--size-slide-thumb-aspect-ratio, 1) * 2 + 1) / 3);

  max-height: calc(var(--size-slide-thumb-max-height-base) / var(--height-fac));
}

.product-gallery__slide .product-thumb__image .image__img {
  object-fit: contain;
}

.product-gallery .product-thumb__image {
  transform: scale(0.75);
  transition: transform var(--fade-speed);
}

.product-gallery__slide.is-active .product-thumb__image {
  transform: scale(1);
}

.section__header {
  flex: 0 1 auto;
  margin-bottom: theme('spacing.y-gutter-sm');
  position: relative;
  text-align: center;
  width: 100%;
}

.section__title {
  display: inline-block;
  font-weight: theme('fontWeight.medium');
  max-width: 100%;
  position: relative;
}

@screen md {
  .section__header {
    margin-left: auto;
    margin-right: auto;
  }

  .section__subtitle {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
}

@screen md {
  .product-gallery__slide {
    width: 40%;
  }

  .product-gallery__slide--realitywear {
    width: 50%;
  }

  .product-gallery .product-thumb--hovers {
    margin-bottom: var(--thumb-block-hover-size);
    margin-top: var(--thumb-block-hover-size);
  }
}
</style>
