import { mapState } from 'vuex'
import { meta as recessMeta } from '~/recess.json'
import transition from '~/app/pageTransition'

export default {
  head() {
    const title = this.title || recessMeta.title
    const titleTemplate = this.titleTemplate || recessMeta.titleTemplate
    const description = this.siteDescription || recessMeta.description
    const siteImage = this.siteImage
    const favicon = this.favicon

    const path = this.$route.path
    const canonicalUrl = this.canonicalUrl
    const href =
      canonicalUrl || `${process.env.canonicalUrl}${path === '/' ? '' : path}`
    const { backgroundColors, textColor } = this.colors || {}
    const head = {
      title,
      titleTemplate,
      meta: [],
      link: [{ hid: 'canonical', rel: 'canonical', href }]
    }

    if (description) {
      /*
        description priority
        1. description from sanity global settings
        2. hardcoded fallback in recess.json
      */
      head.meta.push(
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'twitterDescription',
          name: 'twitter:description',
          content: description
        }
      )
    }
    if (siteImage) {
      const url = this.$sanityImage(siteImage)
        .width(1200)
        .url()
      head.meta.push(
        {
          hid: 'image',
          name: 'og:image',
          content: url
        },
        {
          hid: 'twitterImage',
          name: 'twitter:image',
          content: url
        }
      )
    }
    if (favicon) {
      recessMeta.iconSizes.forEach(size => {
        const url = this.$sanityImage(favicon)
          .ignoreImageParams()
          .size(size, size)
          .fit('max')
          .url()
        const sizes = `${size}x${size}`
        head.link.push({
          hid: `icon-${sizes}`,
          rel: 'icon',
          sizes,
          href: url
        })
      })
    } else {
      head.link = head.link.concat(recessMeta.icons)
    }
    // only set colors server-side, otherwise this will
    // interfere with the page-to-page color transitions
    // handled by the default layout
    if (process.server) {
      const color = this.getTextColor(textColor)
      const themeColor = this.getThemeColor(backgroundColors)
      const backgroundColor = this.getBackgroundColor(backgroundColors)
      const shadowColor = this.getShadowColor(color)
      const page = this.$route.name
      const scheme = this.getScheme(backgroundColor)
      head.meta.push({
        hid: 'color',
        name: 'theme-color',
        content: themeColor
      })
      head.htmlAttrs = {
        lang: 'en',
        style: `--background-color: ${backgroundColor}; --text-color: ${color}; --shadow-color: ${shadowColor}`,
        class: [`page--${page} scheme-${scheme}`]
      }
    }
    return head
  },

  computed: {
    ...mapState({
      siteImage: state => state.settings.siteMeta.image,
      siteDescription: state => state.settings.siteMeta.siteDescription
    })
  },

  /**
   * Change the colors back to their defaults
   * if none are set on the page
   */
  beforeMount() {
    const { backgroundColors, textColor } = this.colors || {}
    if (!backgroundColors || !textColor) {
      this.$store.commit('screen/setPageColors', {
        backgroundColors,
        textColor
      })
    }
  },

  transition
}
